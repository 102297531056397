import Button from "@material-ui/core/Button";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { GetStaticProps, GetStaticPropsContext } from "next";
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import Image from "next/image";
import { useRouter } from "next/router";
import React from "react";

import { InformationContainerLayout } from "@/features/common/components/informationContainer/InformationContainerLayout";
import { Text } from "@/features/common/components/tailwind/Text";
import { View } from "@/features/common/components/tailwind/View";

export const getStaticProps: GetStaticProps = async ({
  locale,
}: GetStaticPropsContext) => {
  return {
    props: {
      ...(await serverSideTranslations(locale, ["common", "notifications"])),
    },
  };
};

const useStyles = makeStyles((theme) =>
  createStyles({
    image: {
      padding: theme.spacing(1),
    },
    buttonsContainer: {
      width: 300,
      marginTop: theme.spacing(4.5),
    },
    homeButton: {
      marginRight: theme.spacing(1.5),
    },
  }),
);

export default function Custom404() {
  const classes = useStyles();
  const router = useRouter();
  const { t } = useTranslation("common");

  const onClickBack = () => router.back();
  const onClickHome = () => router.replace("/");

  return (
    <InformationContainerLayout>
      <Text
        size="normal"
        className="mb-9 text-primary sm:text-[2rem] text-[1.75rem]"
      >
        {t("common:error404Page.title")}
      </Text>

      <View className="p-2">
        <Image
          alt="Page Not Found"
          src="/assets/errorPage/404.png"
          layout="intrinsic"
          width={800}
          height={393}
          priority
        />
      </View>

      <View className="w-[300px] mt-4 flex ">
        <Button
          variant="outlined"
          fullWidth
          color="default"
          className={classes.homeButton}
          onClick={onClickHome}
        >
          {t("common:error404Page.home")}
        </Button>
        <Button
          variant="outlined"
          fullWidth
          color="primary"
          onClick={onClickBack}
        >
          {t("common:error404Page.back")}
        </Button>
      </View>
    </InformationContainerLayout>
  );
}
