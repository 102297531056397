import clsx from "clsx";
import React from "react";

type Props = {
  size:
    | "normal"
    | "small"
    | "large"
    | "xl"
    | "responsiveLarge"
    | "responsiveNormal"
    | "responsiveSmall";
  children?: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
  fullWidth?: boolean;
  ellipsis?: "truncate" | "ellipsis";
};

export const Text: React.FC<Props> = (props: Props) => {
  const { size, children, style, className, fullWidth, ellipsis } = props;

  const getSize = () => {
    if (size === "small") {
      return "text-sm	leading-snug";
    }
    if (size === "large") {
      return "text-lg";
    }
    if (size === "xl") {
      return "text-2xl";
    }
    if (size === "responsiveLarge") {
      return "text-xl sm:text-[1.375rem]";
    }
    if (size === "responsiveNormal") {
      return "text-sm md:text-base";
    }
    if (size === "responsiveSmall") {
      return "text-xs sm:text-sm";
    }
    return "text-base leading-[1.5]";
  };

  const getEllipsis = () => {
    if (ellipsis === "truncate") {
      return "truncate";
    }
    if (ellipsis === "ellipsis") {
      return "text-ellipsis overflow-hidden";
    }
    return "";
  };

  return (
    <span
      className={clsx(
        getSize(),
        className,
        fullWidth && "inline-block w-full",
        getEllipsis(),
      )}
      style={style}
    >
      {children}
    </span>
  );
};
