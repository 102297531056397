import clsx from "clsx";

import { View } from "@/features/common/components/tailwind/View";

import { InformationContainerLayoutProps } from "./type";

export const InformationContainerLayout: React.FC<InformationContainerLayoutProps> =
  (props) => {
    const { flexDirection = "column", position = "absolute" } = props;

    const getFlexDirection = () => {
      if (flexDirection === "row") {
        return "flex-row";
      }

      return "flex-col";
    };
    return (
      <View
        className={clsx(
          position,
          "w-full top-0 bottom-0 right-0 left-0 flex items-center justify-center",
        )}
      >
        <View
          className={clsx(
            getFlexDirection(),
            "flex items-center justify-center",
          )}
        >
          {props.children}
        </View>
      </View>
    );
  };
